/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { useInjectReducer } from 'utils/injectReducer';
// import { withStyles } from '@mui/styles';

import DownloadApp from 'containers/Pages/DownloadApp';
import SwaggerDoc from 'containers/Pages/SwaggerDoc';
import Dashboard from 'containers/Pages/Dashboard';
import SignUp from 'containers/Pages/SignUp';
import SignUpSuccess from 'containers/Pages/SignUpSuccess';

import * as actions from './actions';
import reducer from './reducer';

import 'styles/reset.css';
import 'styles/style.css';

function App(props) {
  useInjectReducer({ key: 'global', reducer });
  const { initApp } = props;

  useEffect(() => {
    const onError = (event) => console.log(event.error);
    window.addEventListener('error', onError);

    return () => {
      window.removeEventListener('error', onError);
    };
  }, []);

  const { i18n } = useTranslation();
  useEffect(() => {
    document.dir = i18n.dir();
    initApp({ locale: i18n.language });
  }, [i18n, i18n.language, initApp]);

  return (
    <div className={clsx({ _rtl: i18n.dir() === 'rtl' })}>
      <Switch>
        {/* https://app.enola.co.il/users/signup?invitation=AX7H7qqa */}
        <Route exact path="/swagger/:version" component={SwaggerDoc} />
        <Route exact path="/join" component={SignUp} />
        <Route exact path="/join/success" component={SignUpSuccess} />
        <Route exact path="/:userCode/families/:familyCode" component={Dashboard} />
        <Route path="/" component={DownloadApp} />
      </Switch>
    </div>
  );
}

Dashboard.propTypes = {
  initApp: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
  initApp: (payload) => dispatch(actions.initApp(payload))
});

export default compose(connect(null, mapDispatchToProps))(App);

// export default withStyles(theme => ({
//   '@global': {
//     a: {
//       color: theme.uiColors.neutral.first[800],
//       fontWeight: 600,
//       fontSize: 16,
//     },
//     body: {
//       background: theme.palette.background.default,
//     },
//   },
// }))(App);
