/*
 * SignUp Sagas
 */

import { takeLatest, put, call, select } from 'redux-saga/effects';
import { api } from 'utils/request';
import { getErrorText } from 'utils/api';
import { push } from 'connected-react-router';

import { decamelizeKeys } from 'humps';
import { REQUEST } from './constants';
import { success, setError, setLoading } from './actions';


const apiInst = api();

function* buildVolunteerSignUpParams({ firstName, lastName, phoneNumber, invitation }) {
  const locale = yield select((state) => state.global.locale || 'he');
  const fullName = [firstName, lastName].filter(Boolean).join(' ');
  return { invitation, volunteer: decamelizeKeys({ phoneNumber, fullName }), locale };
}

function* signUp({ payload: { volunteer } }) {
  try {
    yield put(setLoading(true));
    const params = yield call(buildVolunteerSignUpParams, volunteer);
    yield call(apiInst.post, `/api/v1/volunteers/signup`, params);

    yield put(success());
    yield put(push('/join/success'));
  } catch (e) {
    console.error(e);
    yield put(setError(getErrorText(e)));
  }
}

export default function* appSaga() {
  yield takeLatest(REQUEST, signUp);
}
