import React, { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import { time } from 'utils/datetime';
import clipGreen from 'images/svg/clip-green.svg';
import clipMinGreen from 'images/svg/clip-min-green.svg';
import clipTurquoise from 'images/svg/clip-turquoise.svg';
import clipMinTurquoise from 'images/svg/clip-min-turquoise.svg';
import cs from 'clsx';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';

export function TaskItem({ item, onClick, locale }) {
  const { t } = useTranslation();
  const isNoTime = useMemo(() => item.type === 'no_time', [item]);
  const isFree = useMemo(() => item.status === 'initial', [item]);

  return (
    <li key={`task_${item.id}`} className={cs(styles.task, styles[item.status])}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div role="button" onClick={onClick(item)} tabIndex={0} className={styles.taskBody}>
        <Typography className={cs(styles.status, { [styles.busyLabel]: !isFree })}>
          {t(`taskStatuses.${item.status}`)}
        </Typography>
        {!isNoTime && item.endAt ? (
          <Typography className={styles.time}>
            till <span>{time(item.endAt, locale)}</span>
          </Typography>
        ) : null}
        <Typography className={styles.title}>
          <span>{item.title}</span>
        </Typography>
        {isFree ? (
          <img
            src={isNoTime ? clipMinGreen : clipGreen}
            alt="clip"
            className={cs(styles.clip, { [styles.minClip]: isNoTime })}
          />
        ) : (
          <img
            src={isNoTime ? clipMinTurquoise : clipTurquoise}
            alt="clip"
            className={cs(styles.clip, { [styles.minClip]: isNoTime })}
          />
        )}
      </div>
    </li>
  );
}
