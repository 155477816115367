/*
 * SignUp Actions
 */

import { REQUEST, SUCCESS, FAIL, LOADING } from './constants';

export function signUp(volunteer) {
  return {
    type: REQUEST,
    payload: { volunteer }
  };
}

export function success() {
  return {
    type: SUCCESS
  };
}

export function setError(message) {
  return {
    type: FAIL,
    payload: { message }
  };
}

export function setLoading(value) {
  return {
    type: LOADING,
    payload: value
  };
}
