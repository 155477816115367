import React, { useEffect, useMemo } from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

const setBodyBg = (color) => document.documentElement.style.setProperty('--body-bg', color);

function SwaggerDoc() {
  const {
    i18n: { changeLanguage }
  } = useTranslation();
  const { version } = useRouteMatch().params;

  const swaggerDocUrl = useMemo(
    () => `${process.env.REACT_APP_API_HOST}/api/${version}/swagger_doc`,
    [version]
  );

  useEffect(() => {
    changeLanguage('en');
    setBodyBg('#FFF');
    return setBodyBg('$blue');
  }, [changeLanguage]);

  return <SwaggerUI url={swaggerDocUrl} />;
}

export default SwaggerDoc;
