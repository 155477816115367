/*
 * DashboardReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our Dashboardlication state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import produce from 'immer';
import {
  INIT_PAGE,
  SET_INIT_PAGE_SUCCESS,
  SET_INIT_PAGE_FAILED,
  SET_STATE,
  SET_TASKS
} from './constants';

// The initial state of the Dashboard
export const initialState = {
  loading: true,
  error: false,
  routeParams: {
    userCode: null,
    familyCode: null
  },
  user: null,
  family: null,
  tasks: {
    noTime: [],
    events: []
  }
};

/* eslint-disable default-case, no-param-reassign */
const DashboardReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case INIT_PAGE: {
        draft.loading = true;
        draft.routeParams = action.payload;
        break;
      }
      case SET_INIT_PAGE_SUCCESS: {
        draft.loading = false;
        draft.error = false;
        break;
      }
      case SET_INIT_PAGE_FAILED: {
        draft.loading = false;
        draft.error = action.payload;
        break;
      }
      case SET_STATE: {
        draft.user = action.payload.user;
        draft.family = action.payload.family;
        draft.tasks = action.payload.tasks;
        break;
      }
      case SET_TASKS: {
        // const updateTask = task => (task.id === action.payload.id ? action.payload : task);
        // if (action.payload.type === 'no_time') {
        //   draft.tasks.noTime = state.tasks.noTime.map(updateTask);
        // }
        // draft.tasks.events = state.tasks.events.map(updateTask);
        draft.tasks = action.payload.tasks;
        draft.loading = false;
        break;
      }
    }
  });

export default DashboardReducer;
