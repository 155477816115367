/* eslint-disable import/no-import-module-exports */
import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import history from 'utils/history';

import App from 'containers/App';

import configureStore from './configureStore';
import createTheme from './theme';

import './i18n';

const theme = createTheme();

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

const render = () => {
  ReactDOM.createRoot(MOUNT_NODE).render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <App />
          </StyledEngineProvider>
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['containers/App'], () => {
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([import('intl/locale-data/jsonp/en'), import('intl/locale-data/jsonp/he')])
    )
    .then(render)
    .catch((err) => {
      throw err;
    });
} else {
  render();
}
