import { createTheme, alpha, darken } from '@mui/material/styles';
// import SelectOpenStatusIcon from './icons/selectOpenStatusIcon';

const uiColors = {
  neutral: {
    first: {
      100: '#f0fafc',
      200: '#dff2f8',
      300: '#bde2ee',
      400: '#a1d6e8',
      500: '#90c8ea',
      600: '#7abacf',
      700: '#62a2b7',
      800: '#337286',
      900: '#124a5b',
      1000: '#003b4a'
    },
    second: {
      // Grey colors
      0: '#ffffff',
      100: '#f5f7fa',
      200: '#e4e7eb',
      300: '#cbd2d9',
      400: '#9aa5b1',
      500: '#7b8794',
      600: '#616e7c',
      700: '#52606d',
      800: '#3e4c59',
      900: '#1f923',
      1000: '#1f2933'
    }
  },
  accent: {
    first: {
      100: '#EFFFFF',
      200: '#D6F4F3',
      300: '#B0EBE8',
      400: '#7FCECB',
      500: '#00AFA9',
      600: '#5BAFBA',
      700: '#14919B',
      800: '#3A7A84',
      900: '#0A6C74',
      1000: '#044E54'
    },
    second: {}
  },
  semantic: {
    error: {
      // red colors
      100: '#ffe3e3',
      200: '#ffbdbd',
      300: '#ff9b9b',
      400: '#f86a6a',
      500: '#ef4e4e',
      600: '#e12d39',
      700: '#cf1124',
      800: '#ab091e',
      900: '#8a041a',
      1000: '#610316'
    },
    warning: {
      100: '#fffbea',
      200: '#fff3c4',
      300: '#fce588',
      400: '#fadb5f',
      500: '#f7c948',
      600: '#f4ad1a',
      700: '#de911d',
      800: '#cb6e17',
      900: '#b44d12',
      1000: '#8d2b0b'
    },
    success: {
      // green colors
      100: '#effcf6',
      200: '#c6f7e2',
      300: '#8eedc7',
      400: '#65d6ad',
      500: '#3ebd93',
      600: '#27ab83',
      700: '#199473',
      800: '#147d64',
      900: '#0c6b58',
      1000: '#014d40'
    }
  }
};

export default function Theme() {
  return createTheme({
    uiColors,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        tablet: 1024,
        lg: 1280,
        xl: 1920
      },
      keys: ['xs', 'sm', 'md', 'tablet', 'lg', 'xl']
    },
    palette: {
      primary: {
        main: uiColors.neutral.first[1000]
      },

      secondary: {
        main: alpha(uiColors.neutral.second[1000], 0.7)
      },

      success: {
        main: darken(uiColors.semantic.success[900], 0.6)
      },

      warning: {
        main: darken(uiColors.semantic.warning[600], 0.6)
      },

      error: {
        main: darken(uiColors.semantic.error[800], 0.6)
      },

      background: {
        default: '#F6F7F9'
      },
      action: {
        active: alpha('#002733', 0.54),
        selected: alpha('#002733', 0.08)
      }
    },
    typography: {
      fontFamily: [
        '"Source Sans Pro"',
        'Roboto',
        'Helvetica Neue',
        'Helvetica',
        'Arial',
        'sans-serif'
      ],
      fontSize: 14,
      h1: {
        fontWeight: 400,
        fontSize: 32,
        lineHeight: '40px',
        letterSpacing: '-1.5px',
        color: uiColors.neutral.second[1000]
      },
      h2: {
        fontWeight: 400,
        fontSize: 28,
        lineHeight: '32px',
        letterSpacing: '-0.5px',
        color: uiColors.neutral.second[1000]
      },
      h3: {
        fontWeight: 400,
        fontSize: 24,
        lineHeight: '28px',
        color: uiColors.neutral.second[1000]
      },
      h4: {
        fontWeight: 400,
        fontSize: 22,
        lineHeight: '26px',
        letterSpacing: '0.25px',
        color: uiColors.neutral.second[1000]
      },
      h5: {
        fontWeight: 400,
        fontSize: 18,
        lineHeight: '20px',
        letterSpacing: '0.25px',
        color: uiColors.neutral.second[1000]
      },
      h6: {
        fontSize: 16,
        lineHeight: '20px',
        fontWeight: 600,
        letterSpacing: '0.25px',
        textTransform: 'uppercase',
        color: uiColors.neutral.second[1000]
      },
      subtitle1: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: uiColors.neutral.second[1000]
      },
      subtitle2: {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0.1px',
        color: uiColors.neutral.second[1000]
      },
      body1: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: uiColors.neutral.second[1000]
      },
      body2: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.17px',
        color: uiColors.neutral.second[1000]
      }
    },
    props: {
      MuiSelect: {
        // IconComponent: SelectOpenStatusIcon,
      }
    },
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: uiColors.semantic.error[800]
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 4
          },
          input: {
            padding: '14.5px 12px',
            background: '#fff'
          },
          notchedOutline: {
            borderColor: uiColors.neutral.second[300]
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 4
          },
          sizeSmall: {
            lineHeight: '18px'
          },
          sizeLarge: {
            lineHeight: '32px'
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 4,
            border: `1px solid ${uiColors.neutral.second[200]}`,
            boxSizing: 'border-box'
          }
        }
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            height: '100%',
            boxSizing: 'border-box'
          }
        }
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            padding: 24
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            fontSize: 12,
            top: 'calc(50% - 6px)',
            right: 22
          }
        }
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: alpha(uiColors.neutral.second[1000], 0.23)
          }
        }
      }
    }
  });
}
