import axios from 'axios';
// import { getStore } from 'configureStore';
// import { push } from 'connected-react-router';
import { camelizeKeys } from 'humps';

// import { getSession, clearSession } from 'utils/sessions';
// import { basePathWithSearch } from 'utils/basePath';

const baseConfig = {
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json'
  },
  timeout: 30000
  // paramsSerializer: function (params) {
  //   return qs.stringify(params, { arrayFormat: 'brackets' });
  // },
};

const responseHandler = (res) => {
  res.data = camelizeKeys(res.data);
  return res;
};

export const api = (httpMethod) => {
  const instance = axios.create(baseConfig);

  instance.interceptors.response.use(responseHandler, (error) => {
    if (error.response.status === 401) {
      // const store = getStore();
      // const code = getWorkstationCode();
      // clearSession(code);
      // const action = push(basePathWithSearch(`/sign-in`));
      // store.dispatch(action);
    }

    return Promise.reject(error);
  });

  if (httpMethod !== undefined) {
    return instance[httpMethod];
  }

  return instance;
};
