/**
 *
 * InspirationalModal
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash/fp/compose';

import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import cs from 'clsx';

import starIcon from 'images/svg/star.svg';
import handsIcon from 'images/svg/turquoise-hands.svg';

import Modal from 'components/Modal';
import styles from './index.module.css';

function InspirationalModal({ onClose, isOpen, quote }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} styleOverrides={{ content: styles.content }}>
      <Typography variant="h4" className={cs('modal__title', styles.title)}>
        {quote}
        <img src={starIcon} alt="star" className={styles.starIcon} />
      </Typography>
      <img src={handsIcon} alt="turquoise hands" className={styles.handsIcon} />
    </Modal>
  );
}

InspirationalModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  quote: PropTypes.string.isRequired
};

export default compose(
  memo,
  withStyles((theme) => ({
    description: {
      color: alpha(theme.uiColors.neutral.second[1000], 0.7)
    },
    error: {
      color: theme.uiColors.semantic.error[800]
    }
  }))
)(InspirationalModal);
