/**
 * The SignUp state selectors
 */

import { createSelector } from 'reselect';
import get from 'lodash/fp/get';

import { initialState } from './reducer';

const selectDashboard = (state) => state.signUp || initialState;

export const makeSelectLoading = () => createSelector(selectDashboard, get('loading'));

export const makeSelectError = () => createSelector(selectDashboard, get('error'));
