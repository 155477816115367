import { takeLatest, put, call, select } from 'redux-saga/effects';
import { api } from 'utils/request';

import { INIT_PAGE, UPDATE_TASK } from './constants';
import { initFailed, initSuccess, setState, setTasks } from './actions';

const apiInst = api();

function* fetchRelatedData({ payload: { userCode, familyCode } }) {
  try {
    const { data: user } = yield call(apiInst.get, `/api/v1/volunteers/${userCode}`);
    const { data: family } = yield call(
      apiInst.get,
      `/api/v1/volunteers/${userCode}/families/${familyCode}`
    );
    const { data: tasks } = yield call(
      apiInst.get,
      `/api/v1/volunteers/${userCode}/families/${familyCode}/tasks`
    );

    yield put(setState({ user, family, tasks }));
    yield put(initSuccess());
  } catch (e) {
    console.error(e);
    yield put(initFailed('Not Found'));
  }
}

function* updateTask({ payload }) {
  try {
    const { userCode, familyCode } = yield select((state) => state.dashboard.routeParams);
    const { data } = yield call(
      apiInst.put,
      `/api/v1/volunteers/${userCode}/families/${familyCode}/tasks/${payload.taskId || payload.id}`,
      payload
    );

    yield put(setTasks({ tasks: data }));
  } catch (e) {
    console.error(e);
  }
}

export default function* appSaga() {
  yield takeLatest(INIT_PAGE, fetchRelatedData);
  yield takeLatest(UPDATE_TASK, updateTask);
}
