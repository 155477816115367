/**
 *
 * TaskDetailsModal
 *
 */

import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash/fp/compose';

import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import { time, date } from 'utils/datetime';
import styles from './index.module.css';
import TakenModal from '../TakenModal';


function TaskDetailsModal({ task, onClose, onUpdate, isOpen, userId }) {
  const { t } = useTranslation();
  const isFree = useMemo(() => task.status === 'initial', [task]);
  const isNoTime = useMemo(() => task.type === 'no_time', [task]);

  if (!isFree) {
    return <TakenModal isOpen onClose={onClose} task={task} userId={userId} />;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Typography className={styles.title}>
        {t(`dashboard.modals.taskDetails.taskStatuses.${task.status}`)}
      </Typography>
      {task.endAt && !isNoTime ? (
        <div className={styles.datetime}>
          {t('date')} <span className="free-task__date">{date(task.endAt)}</span> {t('time')}{' '}
          <span className="free-task__time">{time(task.endAt)}</span>
        </div>
      ) : (
        <div className={styles.datetime}></div>
      )}
      <div className={styles.details}>
        <Typography className="modal__title free-task__title">{task.title}</Typography>
        <Typography className="free-task__text">{task.description}</Typography>
      </div>
      <div className="model__box-btns">
        {!isFree && (
          <button onClick={onUpdate(task, 'completed')} className="modal__btn free-task__btn">
            {t('dashboard.modals.taskDetails.actions.complete')}
          </button>
        )}
        {isFree && (
          <button onClick={onUpdate(task, 'in_progress')} className="modal__btn free-task__btn">
            {t('dashboard.modals.taskDetails.actions.take')}
          </button>
        )}
        <button onClick={onClose} className="modal__btn modal__btn_transparent free-task__btn">
          {t('dashboard.modals.taskDetails.actions.cancel')}
        </button>
      </div>
    </Modal>
  );
}

TaskDetailsModal.propTypes = {
  task: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired
};

export default compose(
  memo,
  withStyles((theme) => ({
    description: {
      color: alpha(theme.uiColors.neutral.second[1000], 0.7)
    },
    error: {
      color: theme.uiColors.semantic.error[800]
    }
  }))
)(TaskDetailsModal);
