/**
 *
 * TakenModal
 *
 */

import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash/fp/compose';

import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import cs from 'clsx';

import lightning from 'images/svg/lightning.svg';
import cloud from 'images/svg/cloud.svg';
import clipTurquoise from 'images/svg/clip-turquoise.svg';

import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import styles from './index.module.css';
import { date, time } from '../../../../../utils/datetime';
import UnasignModal from '../UnasignModal';

function TakenModal({ onClose, isOpen, task, userId }) {
  const { t } = useTranslation();

  const isMyTask = useMemo(() => task.assignee?.id === userId, [task, userId]);
  const isNoTime = useMemo(() => task.type === 'no_time' && !task.endAt, [task]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} styleOverrides={{ content: styles.content }}>
      <Typography variant="h4" className={cs('modal__title', styles.title)}>
        {isMyTask
          ? t('dashboard.modals.success.actions.taken')
          : t('dashboard.modals.success.actions.thank')}
        <img src={lightning} alt="lightning" className={styles.imgLightning} />
      </Typography>
      <div className={styles.details}>
        <Typography className="modal__title free-task__title">{task?.title}</Typography>
        <Typography className="free-task__text">{task?.description}</Typography>
        <hr className={styles.separator} />
        {!isNoTime ? (
          <div className={styles.datetime}>
            {t('date')} <span className="free-task__date">{date(task.endAt)}</span> {t('time')}{' '}
            <span className="free-task__time">{time(task.endAt)}</span>
          </div>
        ) : (
          <div className={styles.datetime}></div>
        )}
      </div>
      <button onClick={onClose} className={cs('modal__btn modal__btn_transparent', styles.btn)}>
        {t('dashboard.modals.success.actions.cancel')}
      </button>
      {isMyTask && <UnasignModal onClose={onClose} />}
      <img src={cloud} alt="cloud" className={styles.imgCloud} />
      <img src={clipTurquoise} alt="clip" className={styles.imgClip} />
    </Modal>
  );
}

TakenModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  task: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired
};

export default compose(
  memo,
  withStyles((theme) => ({
    description: {
      color: alpha(theme.uiColors.neutral.second[1000], 0.7)
    },
    error: {
      color: theme.uiColors.semantic.error[800]
    }
  }))
)(TakenModal);
