import React, { memo } from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation, Trans } from 'react-i18next';

import Layout from '../SignUp/Layout';
import styles from './index.module.css';

function SignUpSuccess() {
  const { t } = useTranslation();

  return (
    <Layout>
      <Typography className={styles.title}>
        <span>{t('signUpSuccess.great')}!</span>{' '}
        <Trans i18nKey="signUpSuccess.title">
          <br />
        </Trans>
      </Typography>
      <Typography className={styles.text}>
        <Trans i18nKey="signUpSuccess.instruction">
          <br />
          <span>Thank you!</span>
        </Trans>
      </Typography>
      <Typography className={styles.quote}>
        An inspirational quote to glorify retention - editable and shuffled by the enola interface
      </Typography>
    </Layout>
  );
}

export default memo(SignUpSuccess);
