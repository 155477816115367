import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './styles.css';

export function Switch({ stateChanger }) {
  const [allTasks, setAllTasks] = useState('active');
  const [myTasks, setMyTasks] = useState('adjective');

  const { t } = useTranslation();
  const toggle = (e) => {
    if (e.target.id !== 'active') {
      if (allTasks === 'active') {
        setAllTasks('adjective');
        setMyTasks('active');
        stateChanger('my');
        return;
      }
      setAllTasks('active');
      setMyTasks('adjective');
      stateChanger('all');
    }
  };

  return (
    <div className="switchContainer">
      <button className="on" id={allTasks} onClick={toggle}>
        {t('selectTasks.allTasks')}
      </button>
      <button className="off" id={myTasks} onClick={toggle}>
        {t('selectTasks.myTasks')}
      </button>
    </div>
  );
}
