/**
 *
 * ModalDialog
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { withStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import cs from 'clsx';

import styles from './index.module.css';

function ModalDialog(props) {
  return (
    <Modal
      open={props.isOpen}
      onClose={props.onClose}
      className={cs(styles.modal, props.styleOverrides.modal)}>
      <div className={cs(styles.body, props.styleOverrides.body)}>
        <div className={cs(styles.content, props.styleOverrides.content)}>{props.children}</div>
      </div>
    </Modal>
  );
}

ModalDialog.propTypes = {
  children: PropTypes.node,
  styleOverrides: PropTypes.object,
  // type: PropTypes.oneOf(['warning', 'error', 'info']),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

ModalDialog.defaultProps = {
  styleOverrides: {}
};

export default compose(
  memo,
  withStyles(() => ({
    // error: {
    //   borderColor: theme.uiColors.semantic.error[800],
    // },
    // info: {
    //   borderColor: theme.uiColors.accent.first[500],
    // },
    // warning: {
    //   borderColor: theme.uiColors.semantic.warning[900],
    // },
  }))
)(ModalDialog);
