import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { TaskItem } from 'components/TaskItem';
import { Switch } from 'components/Switch';
import foliage from 'images/svg/foliage.svg';
import { useRouteMatch } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { Grid, Typography } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import LanguageSelector from 'components/LanguageSelector';
import * as selectors from './selectors';
import reducer from './reducer';
import saga from './saga';
import DownloadApp from '../DownloadApp';
import TaskDetailsModal from './modals/TaskDetailsModal';
import SuccessModal from './modals/SuccessModal';
import InspirationalModal from './modals/InspirationalModal';
import * as actions from './actions';

import styles from './index.module.scss';

function Dashboard({ dispatch, user, family, tasks, loading, loaded }) {
  useInjectReducer({ key: 'dashboard', reducer });
  useInjectSaga({ key: 'dashboard', saga });

  const { t, i18n } = useTranslation();
  const match = useRouteMatch();

  const [takenModalOpen, setTakenModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [successItem, setSuccessItem] = useState(null);
  const [inspirationalModalOpen, setInspirationalModalOpen] = useState(false);
  const [selectedTask, changeSelectedTask] = useState(null);
  const [tasksState, setTasksState] = useState('all');
  const [visibleTasks, setVisibleTasks] = useState(tasks);
  const [offset, setOffset] = useState(0);
  const [quote, setQuote] = useState('');
  // const data = Object.values(tasks.thisWeek).flatMap(item => [...item || []])

  // useEffect(() => {
  //   if (data.length) {
  //     setSuccessItem(data[0])
  //     setSuccessModalOpen(true)
  //   }
  // }, [data])

  const filteredTasks = useMemo(() => {
    const week = Object.entries(tasks.thisWeek).reduce((acc, [key, items]) => {
      acc[key] = items.filter((task) => task?.assignee?.id === user.id);
      return acc;
    }, {});
    return {
      noTime: (tasks.noTime || []).filter((task) => task?.assignee?.id === user.id),
      thisWeek: week
    };
  }, [tasks]);

  useEffect(() => {
    if (tasksState === 'my') {
      setVisibleTasks(filteredTasks);
    } else {
      setVisibleTasks(tasks);
    }
  }, [tasksState, tasks, filteredTasks]);

  useEffect(() => {
    setTasksState('all');
    setQuote(family?.quote?.[i18n.language] || t('dashboard.header.motivational'));
  }, [i18n.language]);

  const progress = useMemo(() => {
    if (!family) {
      return 0;
    }

    const allTasks = Object.values(visibleTasks).flatMap((i) =>
      Object.values(i).flatMap((ir) => ir)
    );
    const tasksCounter = allTasks.reduce(
      (acc, task) => {
        const key = task.status === 'initial' ? 'initial' : 'completed';
        acc[key] += 1;
        return acc;
      },
      { initial: 0, completed: 0 }
    );
    const totalTasksCount = allTasks.length;

    return totalTasksCount > 0 ? Math.round((tasksCounter.completed / totalTasksCount) * 100) : 0;
  }, [family, visibleTasks]);

  useEffect(() => {
    const { userCode, familyCode } = match.params;
    dispatch(actions.initPage({ userCode, familyCode }));
  }, [match.params, dispatch]);

  useEffect(() => {
    if (!inspirationalModalOpen && !successModalOpen && !takenModalOpen) {
      document.body.removeAttribute('style');
      window.scrollTo(0, offset);
    }
  }, [inspirationalModalOpen, successModalOpen, takenModalOpen]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!loaded) {
    return <DownloadApp />;
  }

  if (!user) {
    return null;
  }

  const handleClick = (item) => () => {
    changeSelectedTask(item);
  };

  const handleClose = (e) => {
    changeSelectedTask(null);
    setTakenModalOpen(false);
    setInspirationalModalOpen(false);
    setSuccessModalOpen(false);
    if (e.reason || e.reason === '') {
      dispatch(
        actions.updateTask({
          ...selectedTask,
          assignee_id: null,
          status: 'initial',
          reason: e.reason
        })
      );
    }
  };

  const onSubmitReminders = () => {
    setSuccessModalOpen(false);
    setInspirationalModalOpen(true);
    document.body.setAttribute('style', `position: fixed; top: -${offset}px;}`);
    setSuccessItem(null);
  };

  const handleUpdate = (task, status) => () => {
    dispatch(actions.updateTask({ ...task, assignee_id: user.id, status }));
    changeSelectedTask(null);
    setSuccessItem(task);
    setSuccessModalOpen(true);
    setOffset(window.scrollY);
    document.body.setAttribute('style', `position: fixed; top: -${window.scrollY}px;}`);
  };

  return (
    <div className="wrapper">
      <header className="header">
        <div className="container">
          <div className="header__body">
            <Grid justifyContent="space-between" alignItems="center" container>
              <Grid item>
                {/* <p className="header__quote">Motivational quote placed here. - EDITABLE BY ENOLA INTERFACE</p> */}
                <p className="header__quote">{quote}</p>
                <p className="header__welcome">
                  {t('dashboard.header.hello', { user: user.fullName })}
                </p>
                <p className="header__text">
                  {t('dashboard.header.thank', {
                    family: family.title,
                    interpolation: { escapeValue: false }
                  })}
                </p>
                {family.caregiver && (
                  <p className="header__phone">
                    <Trans
                      i18nKey="dashboard.header.caregiver"
                      values={{
                        caregiverName: family.caregiver.fullName
                      }}>
                      <a href={`tel:+${family.caregiver.phoneNumber}`}>
                        {{ caregiverNumber: family.caregiver.formattedPhoneNumber }}
                      </a>
                    </Trans>
                  </p>
                )}
              </Grid>
              <Grid className={styles.languageWrapper} item>
                <LanguageSelector />
              </Grid>
            </Grid>
          </div>
        </div>
        <img src={foliage} alt="decor" className={styles.main__decor} />
      </header>

      <main className="main">
        <section className="to-do">
          <div className="container">
            <Switch stateChanger={setTasksState} />
            <div className="progress-bar">
              <p className="progress-bar__title">
                <span>{progress}%</span> {t('dashboard.main.progress')}
              </p>
              <div className="progress-bar__line" style={{ width: `${progress}%` }}>
                <span></span>
              </div>
            </div>
            <div className="to-do__body">
              <div
                className="to-do__columns"
                style={{ flexFlow: i18n.dir() === 'rtl' ? 'row-reverse' : 'row' }}>
                <div className="to-do__column to-do__column_left">
                  <div className="to-do__name">{t('dashboard.main.weekTitle')}</div>
                  <div className="to-do__days">
                    {Object.entries(visibleTasks.thisWeek).map(([dayName, items]) => (
                      <div className="to-do__day" key={dayName}>
                        <div className="to-do__name-column">{dayName}</div>
                        <ul className="to-do__tasks">
                          {items.length > 0 ? (
                            items.map((task) => (
                              <TaskItem
                                key={`task_${task.id}`}
                                onClick={handleClick}
                                item={task}
                              />
                            ))
                          ) : (
                            <Typography>{t('dashboard.main.noTasks')}</Typography>
                          )}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="to-do__column to-do__column_right">
                  <div className="to-do__name">{t('dashboard.main.dateless.header')}</div>
                  <div className="to-do__week">
                    <div className="to-do__name-column">&nbsp;</div>
                    <ul className="to-do__tasks">
                      {visibleTasks.noTime.length > 0 ? (
                        visibleTasks.noTime.map((task) => (
                          <TaskItem key={`task_${task.id}`} onClick={handleClick} item={task} />
                        ))
                      ) : (
                        <Typography>{t('dashboard.main.noTasks')}</Typography>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {selectedTask && (
        <TaskDetailsModal
          isOpen
          onClose={handleClose}
          onUpdate={handleUpdate}
          task={selectedTask}
          userId={user.id}
        />
      )}
      {successItem && (
        <SuccessModal
          item={successItem}
          isOpen={successModalOpen}
          onClose={handleClose}
          onSubmit={onSubmitReminders}
          progress={`${progress}%`}
        />
      )}
      <InspirationalModal isOpen={inspirationalModalOpen} onClose={handleClose} quote={quote} />
    </div>
  );
}

Dashboard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  user: PropTypes.object,
  family: PropTypes.object,
  tasks: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
  loading: selectors.makeSelectLoading(),
  loaded: selectors.makeSelectLoaded(),
  user: selectors.makeUserSelector(),
  family: selectors.makeFamilySelector(),
  tasks: selectors.makeTasksSelector()
});

export default compose(connect(mapStateToProps, (dispatch) => ({ dispatch })))(Dashboard);
