/*
 * App Actions
 *
 */

import { INIT_APP } from './constants';

/**
 * Dispatched when the repositories are loaded by the request saga
 *
 * @param  {array} repos The repository data
 * @param  {string} username The current username
 *
 * @return {object}      An action object with a type of INIT_APP passing the repos
 */
export function initApp({ locale }) {
  return {
    type: INIT_APP,
    payload: locale
  };
}
