import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useLocation } from 'react-router-dom';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import FormLabel from '@mui/material/FormLabel';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import { useTranslation, Trans } from 'react-i18next';
import { MuiTelInput } from 'mui-tel-input';

import LanguageSelector from 'components/LanguageSelector';
import Layout from './Layout';
import * as actions from './actions';
import saga from './saga';
import reducer from './reducer';
import * as selectors from './selectors';
import styles from './index.module.scss';

function SignUp({ dispatch, error }) {
  useInjectReducer({ key: 'signUp', reducer });
  useInjectSaga({ key: 'signUp', saga });

  const { t } = useTranslation();
  const { search } = useLocation();

  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '+972',
    invitation: ''
  });

  useEffect(() => {
    const invitation = new URLSearchParams(search).get('code') || '';
    setUser({ ...user, invitation });
  }, []); // eslint-disable-line

  const onChange = useCallback(
    ({ target: { name, value } }) => {
      setUser({ ...user, [name]: value });
    },
    [user]
  );

  const handlePhoneChange = useCallback(
    (phoneNumber) => {
      setUser({ ...user, phoneNumber });
    },
    [user]
  );

  const onSubmit = useCallback(() => {
    dispatch(actions.signUp(user));
  }, [user, dispatch]);

  const onCloseAlert = useCallback(() => {
    dispatch(actions.setError(null));
  }, [dispatch]);

  const isValid = useMemo(() => Object.values(user).every(Boolean), [user]);

  return (
    <Layout>
      <Typography className={styles.title}>
        <Trans i18nKey="signUp.title" values={{ family: null }}>
          <span></span>
        </Trans>
      </Typography>
      <Typography className={styles.subtitle}>{t('signUp.subtitle')}</Typography>
      <div className={styles.languageWrapper}>
        <LanguageSelector />
      </div>

      <form action="#" className={styles.form}>
        {error ? (
          <Alert
            severity="error"
            variant="filled"
            className={styles.errorAlert}
            onClose={onCloseAlert}>
            <AlertTitle color="white">{t('signUp.alert.title')}</AlertTitle>
            {error}
          </Alert>
        ) : null}
        <FormControl fullWidth required>
          <FormLabel className={styles.label} htmlFor="firstName" focused={false}>
            {t('signUp.form.firstName')}
          </FormLabel>
          <InputBase
            className={styles.input}
            value={user.firstName}
            onChange={onChange}
            id="firstName"
            name="firstName"
            aria-describedby="firstName"
          />
        </FormControl>
        <FormControl fullWidth required>
          <FormLabel className={styles.label} htmlFor="lastName" focused={false}>
            {t('signUp.form.lastName')}
          </FormLabel>
          <InputBase
            className={styles.input}
            value={user.lastName}
            onChange={onChange}
            id="lastName"
            name="lastName"
            aria-describedby="lastName"
          />
        </FormControl>
        <FormControl fullWidth required>
          <FormLabel className={styles.label} htmlFor="phoneNumber" focused={false}>
            {t('signUp.form.phoneNumber')}
          </FormLabel>
          {/* <InputBase className={styles.input}  value={user.phoneNumber} onChange={onChange} id="phoneNumber" name="phoneNumber" aria-describedby="phoneNumber" /> */}
          <MuiTelInput
            className={styles.phoneInput}
            value={user.phoneNumber}
            onChange={handlePhoneChange}
            id="phoneNumber"
            name="phoneNumber"
            aria-describedby="phoneNumber"
            variant="outlined"
            preferredCountries={['IL']}
          />
        </FormControl>
        <FormControl fullWidth required>
          <FormLabel className={styles.label} htmlFor="invitation" focused={false}>
            {t('signUp.form.invitation')}
          </FormLabel>
          <InputBase
            className={styles.input}
            value={user.invitation}
            onChange={onChange}
            id="invitation"
            name="invitation"
            aria-describedby="invitation"
          />
        </FormControl>
      </form>

      <Typography className={styles.text}>
        <Trans i18nKey="signUp.form.terms">
          <a href="/privacy-policy.pdf" className={styles.action} target="_blank" rel="nofollow">
            Terms of Use
          </a>
          <a href="/privacy-policy.pdf" className={styles.action} target="_blank" rel="nofollow">
            Privacy Policy
          </a>
        </Trans>
      </Typography>

      <Button
        onClick={onSubmit}
        className={styles.btn}
        variant="contained"
        disabled={!isValid}
        fullWidth>
        {t('signUp.form.submit')}
      </Button>
    </Layout>
  );
}

SignUp.propTypes = {
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string
};

const mapStateToProps = createStructuredSelector({
  error: selectors.makeSelectError()
});

export default compose(connect(mapStateToProps, (dispatch) => ({ dispatch })))(SignUp);
