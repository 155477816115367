/*
 * Dashboard Actions
 *
 */

import {
  INIT_PAGE,
  SET_INIT_PAGE_SUCCESS,
  SET_INIT_PAGE_FAILED,
  SET_STATE,
  UPDATE_TASK,
  SET_TASKS
} from './constants';

/**
 * Dispatched when the repositories are loaded by the request saga
 *
 * @param  {array} repos The repository data
 * @param  {string} username The current username
 *
 * @return {object}      An action object with a type of INIT_PAGE passing the repos
 */
export function initPage({ userCode, familyCode }) {
  return {
    type: INIT_PAGE,
    payload: { userCode, familyCode }
  };
}

export function initFailed(message) {
  return {
    type: SET_INIT_PAGE_FAILED,
    payload: {
      message
    }
  };
}

export function initSuccess() {
  return {
    type: SET_INIT_PAGE_SUCCESS
  };
}

export function setState(payload) {
  return {
    type: SET_STATE,
    payload
  };
}

export function setTasks(payload) {
  return {
    type: SET_TASKS,
    payload
  };
}

export function updateTask(payload) {
  return {
    type: UPDATE_TASK,
    payload
  };
}
