/**
 * The global state selectors
 */

import { createSelector } from 'reselect';
import get from 'lodash/fp/get';

import { dayName, daysMap } from 'utils/datetime';
import { initialState } from './reducer';

const selectDashboard = (state) => state.dashboard || initialState;
const selectGlobal = (state) => state.global || {};

const selectRouter = (state) => state.router;

export const makeSelectLoading = () => createSelector(selectDashboard, get('loading'));

export const makeSelectLoaded = () =>
  createSelector(selectDashboard, (state) => !state.loading && !state.error);

export const makeSelectLocation = () => createSelector(selectRouter, get('location'));

export const makeUserSelector = () => createSelector(selectDashboard, get('user'));

export const makeFamilySelector = () => createSelector(selectDashboard, get('family'));

export const makeTasksSelector = () =>
  createSelector(selectGlobal, selectDashboard, (global, state) => {
    const tasksMap = state.tasks.events.reduce(
      (acc, item) => {
        const day = dayName(item.date, global.locale);

        acc.thisWeek[day] = [...acc.thisWeek[day], item];

        return acc;
      },
      { noTime: state.tasks.noTime, thisWeek: daysMap(global.locale) }
    );

    return tasksMap;
  });
