/*
 * DashboardConstants
 */

export const INIT_PAGE = 'app/pages/Dashboard/INIT_PAGE';
export const SET_INIT_PAGE_SUCCESS = 'DASHBOARD/INIT_PAGE_SUCCESS';
export const SET_INIT_PAGE_FAILED = 'DASHBOARD/INIT_PAGE_FAILED';
export const SET_STATE = 'DASHBOARD/SET_STATE';
export const SET_TASKS = 'DASHBOARD/SET_TASKS';
export const UPDATE_TASK = 'DASHBOARD/UPDATE_TASK';
