/**
 *
 * TakenModal
 *
 */

import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash/fp/compose';

import { withStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';

import Modal from 'components/Modal';
import cs from 'clsx';
import { t } from 'i18next';
import { Input, Stack, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import styles from '../TakenModal/index.module.css';

function UnasignModal({ onClose }) {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState('');
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <button
        onClick={() => handleOpen()}
        className={cs('modal__btn modal__btn_transparent', styles.btn)}
        type="button">
        {t('dashboard.modals.taskDetails.actions.unassign')}
      </button>
      <Modal isOpen={open} onClose={handleClose} styleOverrides={{ content: styles.content }}>
        <Typography className="modal__title free-task__title">
          {t('dashboard.modals.unassign.title')}
        </Typography>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            setOpen(false);
            onClose({ reason: input });
          }}>
          <Stack spacing={1}>
            <FormControl>
              <FormLabel className="free-task__text">
                {t('dashboard.modals.unassign.reason')}
              </FormLabel>
              <Input onChange={(e) => setInput(e.target.value)} autoFocus />
            </FormControl>
            <button type="submit" className={cs('modal__btn modal__btn_transparent', styles.btn)}>
              {t('dashboard.modals.taskDetails.actions.unassign')}
            </button>
          </Stack>
        </form>
      </Modal>
    </div>
  );
}

UnasignModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default compose(
  memo,
  withStyles((theme) => ({
    description: {
      color: alpha(theme.uiColors.neutral.second[1000], 0.7)
    },
    error: {
      color: theme.uiColors.semantic.error[800]
    }
  }))
)(UnasignModal);
