import { DateTime, Info } from 'luxon';

export const datetimeFromISO = (value, locale) => DateTime.fromISO(value, { setZone: true, locale, zone: 'utc' });

export const dayName = (value, locale) => {
  const datetime = datetimeFromISO(value, locale);
  return Info.weekdays('long', { locale })[datetime.weekday - 1];
};


export const weekDays = (locale) => {
  const days = Info.weekdays('long', { locale });
  const today = DateTime.now().setLocale(locale).weekday - 1;

  return days.reduce((week, dayname, index) => {
    let i = index - today;

    if (i < 0) {
      i += 7;
    }

    week[i] = dayname; // eslint-disable-line no-param-reassign
    return week;
  }, new Array(7));
};

export const daysMap = (locale) =>
  weekDays(locale).reduce((acc, day) => {
    acc[day] = [];
    return acc;
  }, {});

export const time = (value, locale) =>
  datetimeFromISO(value, locale).toLocaleString(DateTime.TIME_SIMPLE);

export const date = (value, locale) =>
  datetimeFromISO(value, locale).toLocaleString(DateTime.DATE_SHORT);
