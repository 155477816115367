import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import foliage from 'images/svg/foliage.svg';
import LoadingIndicator from 'components/LoadingIndicator';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import Grid2 from '@mui/material/Unstable_Grid2';
import reducer from './reducer';
import saga from './saga';
import * as selectors from './selectors';

import styles from './index.module.scss';

function Layout({ loading, children }) {
  useInjectReducer({ key: 'signUp', reducer });
  useInjectSaga({ key: 'signUp', saga });

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="wrapper">
      <Grid2
        className={styles.root}
        direction="column"
        alignItems="center"
        justify="center"
        container
        spacing={2}>
        <Grid2 className={styles.body} lg={3} md={4} sm={5} xs={10}>
          {children}
        </Grid2>
        <img src={foliage} alt="decor" className={styles.main__decor} />
      </Grid2>
    </div>
  );
}

Layout.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

const mapStateToProps = createStructuredSelector({
  loading: selectors.makeSelectLoading()
});

export default compose(connect(mapStateToProps, (dispatch) => ({ dispatch })))(Layout);
