/*
 * SignUpReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our SignUplication state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import produce from 'immer';
import { SUCCESS, FAIL, LOADING } from './constants';

// The initial state of the SignUp
export const initialState = {
  loading: false,
  error: null
};

/* eslint-disable default-case, no-param-reassign */
const SignUpReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SUCCESS: {
        draft.loading = initialState.loading;
        draft.error = initialState.error;
        break;
      }
      case FAIL: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }
      case LOADING: {
        draft.loading = action.payload;
        break;
      }
    }
  });

export default SignUpReducer;
