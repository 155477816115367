import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Unstable_Grid2';
import NativeSelect from '@mui/material/NativeSelect';
// import Fab from '@mui/material/Fab'
import { useTranslation } from 'react-i18next';
import LanguageIcon from 'icons/language';

import styles from './index.module.scss';

function LanguageSelector({ classes = styles }) {
  const { i18n } = useTranslation();

  const changeLanguage = useCallback(
    ({ target: { value } }) => {
      i18n.changeLanguage(value);
    },
    [i18n]
  );

  return (
    <Grid className={classes.root} spacing={1} alignItems="center" container>
      <Grid item>
        <LanguageIcon className={classes.icon} />
      </Grid>
      <Grid item>
        <NativeSelect
          defaultValue={i18n.language}
          inputProps={{
            name: 'locale',
            id: 'locale'
          }}
          onChange={changeLanguage}
          className={classes.select}
          variant="outlined">
          <option value="en">English</option>
          <option value="he">עִברִית</option>
        </NativeSelect>
        {/* <Fab color="secondary" aria-label="add">
          <img src={languageIcon} alt="language" />
        </Fab> */}
      </Grid>
    </Grid>
  );
}

LanguageSelector.propTypes = {
  classes: PropTypes.object
};

export default LanguageSelector;
