/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our Application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import produce from 'immer';
import { INIT_APP } from './constants';

// The initial state of the App
export const initialState = {
  locale: null
};

/* eslint-disable default-case, no-param-reassign */
const AppReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case INIT_APP: {
        draft.locale = action.payload;
        break;
      }
    }
  });

export default AppReducer;
