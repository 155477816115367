import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { osName } from 'react-device-detect';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import foliage from 'images/svg/foliage.svg';
import { useTranslation } from 'react-i18next';

import ReactStoreBadges from 'react-store-badges';

import LanguageSelector from 'components/LanguageSelector';
import styles from './index.module.scss';

const StoreLinks = {
  Android: 'market://details?id=he.yadtamar.enola',
  iOS: 'itms-apps://apps.apple.com/us/app/enola-app/id6443791731'
};

export default function DownloadApp() {
  const location = useLocation();
  const params = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (StoreLinks[osName]) {
      window.open(StoreLinks[osName], '_top');
    }
  }, [location, params]);

  return (
    <div className="wrapper">
      <div className={styles.main}>
        {/* <div className={styles.container}> */}
        <Grid container>
          <div className={styles.main__body}>
            <Typography className={styles.main__welcome}>{t('downloadApp.welcome')}</Typography>
            <Typography className={styles.main__quote}>{t('downloadApp.notInstalled')}</Typography>
            <Typography className={styles.main__text}>{t('downloadApp.instruction')}</Typography>
            <div className={styles.languageWrapper}>
              <LanguageSelector />
            </div>
          </div>
        </Grid>
        <img src={foliage} alt="decor" className={styles.main__decor} />

        <Grid
          container
          spacing={1}
          columns={{ xs: 2 }}
          justifyContent="center"
          alignContent="center"
          sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
          <Grid item>
            <ReactStoreBadges
              platform="android"
              url={StoreLinks.Android}
              locale="en-us"
              target="_top"
              width="200px"
              height="80px"
            />
          </Grid>
          <Grid item>
            <ReactStoreBadges
              platform="ios"
              url={StoreLinks.iOS}
              locale="en-us"
              target="_top"
              width="200px"
              height="80px"
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
