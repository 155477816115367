export const getErrorText = (error, defaultText = '') => {
  let errorText = null;

  try {
    errorText = error.errorText;
  } catch (e) {
    /* empty */
  }

  try {
    errorText = error.data.error;
  } catch (e) {
    /* empty */
  }

  if (!errorText) {
    try {
      errorText = error.response.data.error;
    } catch (e) {
      /* empty */
    }
  }

  if (!errorText) {
    try {
      errorText = error.response.headers['x-error-message'];
    } catch (e) {
      /* empty */
    }
  }

  return errorText || defaultText;
};
