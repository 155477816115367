/**
 *
 * SuccessModal
 *
 */

import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash/fp/compose';

import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import cs from 'clsx';

import lightning from 'images/svg/lightning.svg';
import cloud from 'images/svg/cloud.svg';
import jackdaw from 'images/svg/jackdaw.svg';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { useTranslation, Trans } from 'react-i18next';

import Modal from 'components/Modal';
import styles from './index.module.css';

function SuccessModal({ onClose, onSubmit, isOpen, progress, item = {} }) {
  const { t, i18n } = useTranslation();

  const getEndDate = useCallback((startDate) => 
    // const endDate = new Date(startDate)
    // endDate.setMonth(endDate.getMonth() + 3)
    // return endDate.toISOString()
    startDate
  , []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} styleOverrides={{ content: styles.content }}>
      <Typography variant="h4" className={cs('modal__title', 'reminder__title', styles.title)}>
        {t('dashboard.modals.reminder.thank')}
        <img src={lightning} alt="lightning" className={styles.imgLightning} />
      </Typography>
      <Typography variant="h4" className={cs('reminder__text', styles.text)}>
        <Trans
          i18nKey="dashboard.modals.reminder.content"
          values={{ progress }}
          components={{ colored: <span /> }}
        />
      </Typography>
      {item && item.type !== 'no_time' && (
        <div className={styles.addToCalendar}>
          <AddToCalendarButton
            name={item.title}
            description={item.description}
            options={['Apple', 'Google']}
            startDate={item.date}
            endDate={getEndDate(item.date)}
            startTime={item.startAt.replace(/\+\d+/, '')}
            endTime={item.endAt.replace(/\+\d+/, '')}
            timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
            // recurrence="FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TU,WE,TH,FR"
            buttonStyle="text" // round, custom
            label={t('dashboard.modals.reminder.options.calendar')}
            size="4"
            language={i18n.language}
            hideCheckmark
          />
        </div>
      )}
      <button onClick={onSubmit} className={cs('modal__btn modal__btn_transparent', styles.btn)}>
        {t('dashboard.modals.reminder.actions.submit')}
      </button>
      <img src={cloud} alt="cloud" className={styles.imgCloud} />
      <img src={jackdaw} alt="jackdaw" className={styles.jackdaw} />
    </Modal>
  );
}

SuccessModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  progress: PropTypes.string.isRequired,
  item: PropTypes.object
};

export default compose(
  memo,
  withStyles((theme) => ({
    description: {
      color: alpha(theme.uiColors.neutral.second[1000], 0.7)
    },
    error: {
      color: theme.uiColors.semantic.error[800]
    }
  }))
)(SuccessModal);
